/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2018 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
/* eslint prefer-rest-params: 0, func-names: 0 */
import autobind from 'autobind-decorator';

export default function bindAndCatch(target, key, descriptor) {
  if (arguments.length === 1 || typeof descriptor.value !== 'function') {
    throw new TypeError('@bindAndCatch applies only to methods');
  }
  const fn = descriptor.value;

  descriptor.value = function () {
    try {
      return fn.apply(this, arguments);
    } catch (err) {
      // throw the error from setState() so that the error gets
      // caught in componentDidCatch()
      this.setState(() => {
        throw err;
      });
    }
  };
  return autobind(target, key, descriptor);
}
