/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* global window, document */
import { loadScript } from '../core/DomUtil';

// Split this code out of Discovery
// Makes it easier to call, and prevents bi-directional dependencies when we load dc-extras from our providers

export default function loadExtras() {
  if (!window.adobe_dc_sdk.launchOptions.loadExtrasPromise) {
    const extrasJsURI = window.adobe_dc_sdk.launchOptions.dc_extras_js_uri;
    window.adobe_dc_sdk.launchOptions.loadExtrasPromise = loadScript(extrasJsURI).then(() => {
      // execute the extras index file
      window.adobe_dc_sdk['dc-extras'](window.adobe_dc_sdk.aliases['dc-extras']);
    });

    if (!document.getElementById('dc-extras-css-id')) {
      const link = document.createElement('link');
      link.href = window.adobe_dc_sdk.launchOptions.dc_extras_css_uri;
      link.rel = 'stylesheet';
      document.head.appendChild(link);
    }
  }
  return window.adobe_dc_sdk.launchOptions.loadExtrasPromise;
}
