/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2022 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* eslint-disable no-underscore-dangle */

/* global window */

/**
 * @file Utilities for managing cookies.
 *
 * document.Cookie api is a bit awkard to work with. The
 * utilities in this file aim to make it easier to work with cookies
 * by providing familar setItem, getItem, removeItem operations
 */

// Based on https://www.chromium.org/updates/same-site/incompatible-clients
const isSameSiteNoneCompatible = () => {
  const userAgent = window.UAParser && new window.UAParser();
  if (!userAgent) {
    return false;
  }

  const os = userAgent.getOS();
  const browser = userAgent.getBrowser();
  if (os.name === 'Mac OS' && os.version.startsWith('10.14.') && browser.name === 'Safari') {
    return false;
  }
  if (os.name === 'iOS' && os.version.startsWith('12.')) {
    return false;
  }
  return true;
};

function getCookies() {
  return window.document.cookie ? window.document.cookie.split('; ') : [];
}

/**
 * Get a cookie
 * @param { string } searchKey - the cookie name - Required
 * @return { String | null } The value associated with that key.
 * Null if the key does not exist
 */
function getItem(searchKey) {
  const cookies = getCookies();
  const item = cookies.find(cookie => cookie.trim().startsWith(`${searchKey}=`));
  if (!item) {
    return null;
  }
  const [, ...others] = item.split('=');
  const value = others.join('=');
  return value;
}

/**
 * Set a cookie
 * @param { string } key - the cookie to create or override - Required
 * @param { string } value - The value for the cookie - Required
 * @param { Object } attrs - Cookie attributes to apply - Optional
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie#attributes
 * @param { String } attrs.domain - The domain name - Optional
 * @param { String } attrs.expires - The expiry date string in UTC format - Optional
 * @param { number } attrs.maxAge - The max-age - Optional
 * @param { String } attrs.path - The path - Optional
 * @param { Boolean } attrs.secure - If we should set secure flag - Optional
 */
function setItem(key, value, attrs = {}) {
  let cookieString = `${key}=${value}`;
  if (attrs.domain) {
    cookieString += `; domain=${attrs.domain}`;
  }
  if (attrs.path) {
    cookieString += `; path=${attrs.path}`;
  }
  if (attrs.expires) {
    cookieString += `; expires=${attrs.expires}`;
  }
  if (attrs.maxAge) {
    cookieString += `; max-age=${attrs.maxAge}`;
  }
  if (attrs.secure) {
    cookieString += '; secure';
  }
  if (attrs.samesite && (attrs.samesite !== 'None' || isSameSiteNoneCompatible())) {
    cookieString += `; samesite=${attrs.samesite}`;
  }
  window.document.cookie = cookieString;
}

/**
 * Determines if a cookies is already set
 * @param { string } searchKey - the cookie we're looking for - Required
 * @return {Boolean} True if the item is already set, false otherwise
 */
function hasItem(searchKey) {
  const cookies = getCookies();
  return !!cookies.find(cookie => cookie.trim().startsWith(`${searchKey}=`));
}

/**
 * Remove a given cookie
 * @param { string } key - the cookie name to remove - Required
 * @param { Object } attrs - Cookie attributes to apply - Optional
 * @param { String } attrs.domain - The domain name. Defaults to '.adobe.com'
 * @param { String } attrs.expires - The expiry date string in UTC format. Defaults to 'Thu, 01 Jan 1970 00:00:01 GMT'
 * @param { number } attrs.maxAge - The max-age. Defaults to '-99999999'
 * @param { String } attrs.path - The path. Defaults to '/'
 * @param { Boolean } attrs.secure - If we should set secure flag. Defaults to 'true'
 */
function removeItem(key, attrs = {
  domain: '.adobe.com',
  expires: 'Thu, 01 Jan 1970 00:00:01 GMT',
  maxAge: -99999999,
  path: '/',
  secure: true,
}) {
  setItem(key, '', attrs);
}

/**
 * Get all the cookie keys
 * @return {Array} List of keys
 */
function keys() {
  const cookies = getCookies();
  return cookies.map(cookie => decodeURIComponent(cookie.split('=')[0]));
}

export {
  getItem,
  hasItem,
  keys,
  removeItem,
  setItem,
};
