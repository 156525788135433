/*************************************************************************
 * ADOBE SYSTEMS INCORPORATED
 *  Copyright 2021 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying it.
 * If you have received this file from a source other than Adobe, then your
 * use, modification, or distribution of it requires the prior written
 * permission of Adobe.
 **************************************************************************/

/* global window, document */

import EventEmitter from 'eventemitter3';
import { getSingletonFunction } from '../core/ProviderUtil';
import UserAPI from './UserAPI';
import { auth2 } from './Auth2API';
import { logging } from './LoggingAPI';
import { router } from './RouterAPI';

const DC_PREFERENCES_CATEGORY = 'dcweb';
const THEME = {
  LIGHT: 'light',
  DARK: 'dark',
  AUTO: 'auto',
};

const logger = logging.getLogger('ThemeProvider');

/**
 * @classdesc
 * Service provider with access to app appearance
 * @class
 */
class ThemeAPI {
  _emitter = new EventEmitter();

  get emitter() {
    return this._emitter;
  }

  subscribe = subscriber => {
    this.emitter.addListener('theme-change', subscriber);
  }

  unsubscribe = subscriber => {
    this.emitter.removeListener('theme-change', subscriber);
  }

  /**
   * @description
   * Standard provider ready() method to allow lazy instantiation of API.
   * @method
   * @returns {Promise} - promise that resolves when user provider has been instantiated
   */
  ready() {
    return Promise.resolve(this);
  }

  darkModeMQL = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)');

  /**
   * @description
   * Check if user device prefers dark color scheme
   * @method
   * @returns {boolean} - if dark scheme is prefered
   * @public
   */
  prefersDarkMode = () => this.darkModeMQL.matches;

  /**
   * @description
   * Force auto theme change, read device theme setting,
   * used for prefers color scheme MQL
   * @private
   */
  _changeThemeToAuto = () => this.changeTheme('auto');

  /**
   * @description
   * Register event listeners for prefers color scheme media query and set auto theme
   * Observe device theme change
   * @private
   */
  _setMQL = () => {
    if (!this._readyMQL) {
      if (this.darkModeMQL.addEventListener) {
        this.darkModeMQL.addEventListener('change', this._changeThemeToAuto);
      } else if (this.darkModeMQL.addListener) { // Safari
        this.darkModeMQL.addListener(this._changeThemeToAuto);
      }

      this._readyMQL = true;
    }
  };

  /**
   * @description
   * Remove event listeners from prefers color scheme media query
   * @private
   */
  _tearDownMQL = () => {
    if (this._readyMQL) {
      if (this.darkModeMQL.removeEventListener) {
        this.darkModeMQL.removeEventListener('change', this._changeThemeToAuto);
      } else if (this.darkModeMQL.addListener) { // Safari
        this.darkModeMQL.removeListener(this._changeThemeToAuto);
      }

      this._readyMQL = false;
    }
  }

  /**
   * @description
   * Determines the user theme prefe based on the following precendence:
   *  1. Query parameter
   *  2. Signed-in in user theme preference
   *  3. Defaults to 'auto' device/browser setting
   * @method
   * @returns {string} - 'light', 'dark', 'auto'
   * @public
   */
  async getThemePref() {
    const queryParams = router.getQueryParams();
    if (queryParams.theme) {
      return queryParams.theme;
    }

    if (auth2.isSignedIn) {
      // DC Web setting from the user preference is temporary place to store theme value,
      // location of where theme is stored is TBD
      const userProvider = await UserAPI.getInstance();
      const userPref = await userProvider.getPreferences(DC_PREFERENCES_CATEGORY) || {};
      return userPref.theme || THEME.AUTO; // apperance
    }
    // UserAPI fails for anonymous users, default value for anonymous users should be auto
    // get theme from local storage TBD
    return THEME.AUTO;
  }

  /**
   * @description
   * Set theme user preference
   * @method
   * @param {string} value - 'light', 'dark', 'auto'
   * @public
   */
  async setThemePref(value) {
    if (auth2.isSignedIn) {
      // DC Web setting from the user preference is temporary place to store theme value,
      // location of where theme is stored is TBD
      const userProvider = await UserAPI.getInstance();
      const userPref = await userProvider.getPreferences(DC_PREFERENCES_CATEGORY) || {};
      return userProvider.setPreferences(DC_PREFERENCES_CATEGORY, { ...userPref, theme: value });
    }
    // save in local storage for anonymous user is TBD
  }

  /**
   * @description
   * Returns active theme as string or set theme if not set
   * @method
   * @returns {string} - 'light' or 'dark' string based on theme
   * @param {object} props  - (props) May contain:
   *      - {string} controlledValue - (Optional) override existing value, force theme value
   * @public
   */
  async getTheme(props = {}) {
    if (!this.theme) {
      await this.setupTheme(props);
    }

    return this.theme; // returns theme value without a wait for CSS file to be loaded
  }

  /**
   * @description
   * Theme setup logic for Light, Dark and Auto mode
   * @method
   * @returns {Promise} - promise that resolves when theme is set
   * @param {object} props  - (props) May contain:
   *      - {string} controlledValue - (Optional) override existing value, force theme value
   * @public
   */
  async setupTheme(props = {}) {
    const {
      controlledValue, // Override default user setting
    } = props;

    const themePref = controlledValue || await this.getThemePref();

    if (themePref && !Object.values(THEME).includes(themePref)) {
      logger.error('Invalid theme value, defaulting to device setting');
      this.theme = THEME.AUTO;
    }

    if (themePref === THEME.LIGHT) {
      this.theme = THEME.LIGHT;
    }

    if (themePref === THEME.DARK) {
      this.theme = THEME.DARK;
    }

    if (!themePref || themePref === THEME.AUTO) {
      this.theme = this.prefersDarkMode() ? THEME.DARK : THEME.LIGHT;
      this._setMQL();
    } else {
      this._tearDownMQL();
    }

    if (this.theme === THEME.DARK) {
      // load Spectrum V2 Dark CSS
      await window.adobe_dc_sdk.appLauncher.fetchSpectrumV2DarkCss();
    }

    // change scroll bars color
    document.documentElement.style.setProperty('color-scheme', this.theme);
  }

  /**
   * @description
   * Change theme and emit theme change event to subscribers
   * @method
   * @returns {Promise} - promise that resolves when theme is set
   * @param - {string} controlledValue - (Optional) foce theme value
   * @public
   */
  async changeTheme(controlledValue) {
    return this.setupTheme({ controlledValue }).then(() => {
      this.emitter.emit('theme-change', this.theme);
    });
  }
}

// This allows for providers.x().then() to be called before providers.x(config).
ThemeAPI.getInstance = getSingletonFunction(ThemeAPI);
export default ThemeAPI;
