/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* global window */
/* eslint import/prefer-default-export: 0 */
/**
 * @file
 */

/**
 * @classdesc
 * Provider for Router portion of Service API
 * @class
 */
class RouterAPI {
  /**
   * @description
   * Retrieve the query parameters as they existed at the start of the web app
   * @method
   * @returns {object} a map of query parameters
   * @public
   */
  get queryParams() {
    // this used to call memoize -- which is not part of lodash/core
    if (!this._queryParams) {
      this._queryParams = this.getQueryParams(window.location.search);
    }
    return this._queryParams;
  }

  /**
   * Get the window.location.
   * This allows it to be mocked for testing.
   */
  getLocation() {
    return window.location;
  }

  /**
   * @description
   * Parses search portion of URL into map of query parameters.
   * @method
   * @param {string} search - Search portion of URL. Optional. If not present,
   *                          uses window.location.search.
   * @returns {object} - Map of query parameter values by key
   * @public
   */
  getQueryParams(search) {
    const dict = {};

    const params = new URLSearchParams((search || window.location.search).substr(1));
    params.forEach((value, key) => dict[key] = value);
    return dict;
  }

  /**
     * @description
     * Get a standard new URL from the window.location.href.
     * The result will have a URLSearchParams searchParams property.
     */
  getURL() {
    return new URL(this.getLocation().href);
  }

  /**
   * @description
   * Get a standard new URLSearchParams from the window.location.search.
   */
  getURLSearchParams() {
    return new URLSearchParams(this.getLocation().search);
  }

  /**
   * @description
   * Returns given path with current query parameters
   * @method
   * @param {string} path - Relative path
   * @param {object} options - (Optional) May contain:
   *      - {String} search - (Optional) search string FOR TESTING ONLY!
   *      - {Boolean} preserveHash - (Optional) Preserves current hash in new url
   *      - {String[]} removeParams - (Optional) Query params to remove
   * @returns {string} - Same path but with current query parameters possibly included
   * @public
   */
  withQueryParams(path, options = {}) {
    // Separate query params from path and convert to map
    const paramsIndex = path.indexOf('?');
    const params = paramsIndex >= 0 ? path.substr(paramsIndex) : '';
    const pathParams = params ? this.getQueryParams(params) : {};
    const hash = options.preserveHash ? window.location.hash : '';

    // Remove trailing slash from path portion of passed path
    const pathWithoutParams = path.replace(/\?.*/, '').replace(/[/]?$/, '');

    // Get map of current query params
    const search = options.search || window.location.search;
    const searchParams = new URLSearchParams(search);

    // Don't preserve current folder or asset, or any dialog query params
    const reservedParams = ['uri', 'assetUri', 'renderingAssetUri', 'showUpsell', 'path', 'connectorid'];
    const paramsToRemove = options.removeParams ?? [];
    [...reservedParams, ...paramsToRemove].forEach(p => searchParams.delete(p));

    Object.keys(pathParams).forEach(key => searchParams.set(key, pathParams[key]));

    // Assemble new query param string
    const mergedSearch = searchParams.toString();

    // Attach to path, previously relieved of its slash and query params
    return `${pathWithoutParams}/${(mergedSearch.length ? '?' : '')}${mergedSearch}${hash}`;
  }

  /**
   * @description
   * If component is already a descendant of a router, return it. Otherwise,
   * return it wrapped in a MemoryRouter so that its react-router components
   * (Route, Link, et al) continue to work.
   * @method
   * @param {object} props - Props passed to component
   * @param {function} component - Target component
   * @returns {object} - Either component itself or component wrapped in MemoryRouter
   * @public
   */
  withMemoryRouter() {
    // this implementation will be replaced once dc-extras have loaded
    throw new Error('dc-extras must be loaded');
  }

  /**
   * @description
   * Sets history object
   * @method
   * @public
   */
  setHistory(value) {
    this.history = value;
  }

  /**
   * @description
   * Standard provider ready() method to allow lazy instantiation of API.
   * @method
   * @returns {Promise} - promise that resolves when router provider has been instantiated
   */
  ready() {
    return Promise.resolve(this);
  }
}

const router = new RouterAPI();
export { router };
