/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* global window */

import Deferred from './Deferred';

let logger;

function logWarning(msg, err) {
  console.warn(msg, err);
  if (!logger) {
    // Access LoggingAPI via adobe_dc_sdk object to get around circular dependency
    if (window.adobe_dc_sdk
            && window.adobe_dc_sdk['dc-core']
            && window.adobe_dc_sdk.aliases) {
      const loggingAPI = window.adobe_dc_sdk['dc-core'](window.adobe_dc_sdk.aliases['dc-core']).logging;
      if (loggingAPI) {
        logger = loggingAPI.getLogger('ProviderUtil');
      }
    }
  }
  if (logger) {
    logger.warn(msg, err);
  }
}

/**
 * @description
 * Provides a method to create a getInstance function that resolves a singleton via Promise.
 * @method
 * @param {Object} Construct - The object for which we need the singleton created
 * @param {boolean} [argsRequired = false] - The flag for any arguments required or not
 * @param {boolean} [neverReject = false] - The flag for hanging rather than rejecting the Promise
 * @returns {Object} - getInstance method on the object.
 * @example
 * car.getInstance = car.getSingletonFunction(car)
 * car.getInstance = car.getSingletonFunction(car, true)
 * car.getInstance(args) will create the singleton, while car.getInstance() will defer until it's created.
 * @public
 */
function getSingletonFunction(Construct, /* boolean*/ argsRequired, /* boolean*/ neverReject) {
  const defer = new Deferred();

  function getInstance(...args) {
    if (!defer.result && (!argsRequired || args.length)) {
      defer.result = new Construct(...args);
      defer.result.ready().then(result => defer.resolve(result), err => {
        // Reject the original Deferred Promise unless told otherwise.
        if (neverReject && !(args && args[0] && args[0].neverReject === false)) {
          logWarning('Non-rejected Promise silently caught', err);
        } else {
          return defer.reject(err);
        }
      });
    }
    return defer.promise;
  }
  return getInstance;
}

// eslint-disable-next-line
export { getSingletonFunction };
