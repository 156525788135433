/*************************************************************************
 * ADOBE SYSTEMS INCORPORATED
 *  Copyright 2017 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying it.
 * If you have received this file from a source other than Adobe, then your
 * use, modification, or distribution of it requires the prior written
 * permission of Adobe.
 **************************************************************************/

/* eslint no-undef: 0 */

/**
 * @file
 */
import loadExtras from './loadExtras';
import { getSingletonFunction } from '../core/ProviderUtil';

/**
 * @classdesc
 * Service provider with access to DCAPI
 * Contains implementation of various DCAPI operations.
 * @class
 */
class ConversionAPI {
  /**
     * @description
     * Standard provider ready() method to allow lazy instantiation of API.
     * @method
     * @returns {Promise} - promise that resolves when conversion provider has been instantiated
     * @public
     */
  ready() {
    return loadExtras().then(() => {
      this.ConversionImplementation = window.adobe_dc_sdk.ConversionImplementation;
      const conversionImpl = new this.ConversionImplementation();
      return conversionImpl.ready();
    });
  }
}
// This allows for providers.x().then() to be called before providers.x(config).
ConversionAPI.getInstance = getSingletonFunction(ConversionAPI);
export default ConversionAPI;
