/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/**
 * @description
 * Misc utility functions related to environment, i.e. 'prod', 'stage', et al.
 */

import { getMetaContent } from './DomUtil';

let envVars = {};

/* @description
 * Sets AdobeDC environment variables
 * Used by Discovery to set all the variables found in the versionManifest
 * @memberof EnvUtil
 * @method
 * @param {object} vars
 * @example {test: true}
 */
function setEnvVars(vars) {
  envVars = { ...envVars, ...vars };
}

/**
 * @description
 * Gets an AdobeDC environment variable by name.
 * @memberof EnvUtil
 * @method
 * @param {string} name - environment variable name
 * @param {string} [def] - default value if not found is optional.
 * @returns {string} - The AdobeDC environment name
 * @example 'prod' for 'dc.acrobat.com', etc.
 */
function getEnvVar(name, def) {
  // search in versionManifest first.
  let val = envVars[name];
  if (val != null) {
    return val;
  }

  // Fallback to the meta tags
  // If we don't see warnings after a while we can remove the meta tags.
  val = getMetaContent(name);
  return val != null ? val : def;
}

/**
 * @description
 * Gets current environment by name, default.
 * @memberof EnvUtil
 * @method
 * @param {string} [hostname] - It is Optional. Used primarily for testing
 * @returns {string} - The current app environment
 * @example 'prod' for 'dc.acrobat.com' or 'dc.adobe.com', etc.
 */
function getHostEnv(name) {
  return getEnvVar(name || 'server_env') || 'localdev';
}

/**
 * @description
 * To check whether environment is stage or prod.
 * @memberof EnvUtil
 * @method
 * @param {string} [hostname] - It is Optional. Used primarily for testing
 * @returns {boolean} - True if environment is stage or prod
 */
function isStageOrProd(hostname) {
  const env = getHostEnv(hostname);
  return env === 'stage' || env === 'prod';
}

/**
 * @description
 * To check whether the environment is Prod or not
 * @memberof EnvUtil
 * @method
 * @param {string} [hostname] - (Optional) For testing
 * @returns {boolean} - True is the environment is prod
 */
function isProd(hostname) {
  const env = getHostEnv(hostname);
  return env.toLowerCase() === 'prod';
}

export {
  getHostEnv, isStageOrProd, isProd, getEnvVar, setEnvVars,
};
