/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* global window, fetch */
/**
 * @file
 */

import { API } from 'adobe-dcapi-web';
import uuidv4 from 'uuid/v4';
import { auth2 } from './Auth2API';
import { getEnvVar } from '../core/EnvUtil';
import { getSingletonFunction } from '../core/ProviderUtil';

// we support listeners on DCAPI events so that we can manage
// limits locally
const listeners = [];
const errorListeners = [];

const notifyListeners = (operation, options, result) => {
  listeners.forEach(fn => {
    fn(operation, options, result);
  });
};

const notifyErrorListeners = (operation, options, errorResult) => {
  errorListeners.forEach(fn => {
    fn(operation, options, errorResult);
  });
};

const localStorageAuthKey = 'pdfnow.auth';

/**
 * @class
 */
class DcapiAPI {
  /**
   * Check and see if there's a cached response for this request.
   * For now, we expect to use this for discovery.discover and users.get_user only
   */
  static fetchCache(operation) {
    // check to see if we have a cached discovery response
    if (window.adobe_dc_sdk
        && window.adobe_dc_sdk.cache
        && window.adobe_dc_sdk.cache.dcapi
        && window.adobe_dc_sdk.cache.dcapi[operation]) {
      const result = window.adobe_dc_sdk.cache.dcapi[operation];
      // cache only the first request
      delete window.adobe_dc_sdk.cache.dcapi[operation];
      return result;
    }
    return null;
  }

  static getAuthString() {
    try {
      const authString = window.localStorage.getItem(localStorageAuthKey);
      return Promise.resolve(authString);
    } catch (e) {
      const dcExtensionStorage = window.adobe_dc_sdk.util.dcExtensionStorage;
      if (dcExtensionStorage) {
        return dcExtensionStorage.getItems([localStorageAuthKey]).then(result => result[localStorageAuthKey]);
      }
      return Promise.resolve(null);
    }
  }

  /**
   * Retrieves the user's anonymous auth information
   *
   * @returns An object detailing the authentication
   *          of the current anonymous user. Will return null if the user
   *          token has expired or will expire soon. Will also return null
   *          if there isn't auth info stored.
   */
  static getAnonymousAuth() {
    return this.getAuthString().then(authString => {
      const authObject = JSON.parse(authString);
      if (authObject === null) {
        return null;
      }
      authObject.expiresAt = new Date(authObject.expiresAt);
      return authObject;
    }).catch(() => null);
  }

  /**
   * Generates a new anonymous token and places it in local
   * storage
   *
   * @param ipOverride - optional IP Override used for testing
   */
  static generateNewAnonymousToken() {
    // You might wonder why this module isn't async and use 'await'
    // it's becaause in that state, we get a regenerator runtime error.
    // It's easier to avoid the sync than to reconfigure to avoid the regenerator error
    return new Promise((resolve, reject) => {
      const pdfnowURI = getEnvVar('pdfnow_uri');
      const uri = `${pdfnowURI}/users/anonymous_token`;
      const fetchBody = { method: 'POST' };
      fetch(uri, fetchBody).then(response => {
        response.json().then(json => {
          const expiresAt = new Date();
          expiresAt.setMilliseconds(json.expires_in + expiresAt.getMilliseconds());

          const innerContent = { expiresAt, ...json };

          if (json.discovery) {
            if (json.discovery.self) {
              delete json.discovery.self;
            }
            delete json.discovery;
          }
          const pdfnowAuth = { expiresAt, ...json };

          try {
            window.localStorage.setItem(localStorageAuthKey, JSON.stringify(pdfnowAuth));
          } catch (e) {
            const dcExtensionStorage = window.adobe_dc_sdk.util.dcExtensionStorage;
            dcExtensionStorage.setItems({ [localStorageAuthKey]: JSON.stringify(pdfnowAuth) });
          }
          resolve({ content: innerContent });
        }, reject);
      }, reject);
    });
  }

  static getAnonConfig = () => {
    const pdfnowURI = getEnvVar('pdfnow_uri');
    const dcapiAccept = `application/vnd.adobe.dc+json; profile="${pdfnowURI}/schemas/discovery_v1.json"`;
    const config = {
      discovery: {
        accept: dcapiAccept,
        uri: `${pdfnowURI}/discovery`,
      },
      identityAccess: {
        getSessionToken: () => DcapiAPI.getAnonymousAuth().then(cachedAuthInfo => {
          let shouldRequestNewToken = true;

          if (cachedAuthInfo !== null && cachedAuthInfo.expiresAt) {
            const hour = 1000 * 60 * 60;
            const anHourFromNow = Date.now() + hour;

            shouldRequestNewToken = cachedAuthInfo.expiresAt.getTime() < anHourFromNow;
          }
          return shouldRequestNewToken ? this.generateNewAnonymousToken() : Promise.resolve({ content: cachedAuthInfo });
        }),
      },
      'x-api-app-info': 'dc-web-app',
      'x-api-client-id': 'api_browser',
    };
    // check to see if we have a cached discovery response
    const result = DcapiAPI.fetchCache('discovery.discover');
    if (result) {
      config.discoveryPromise = Promise.resolve({ content: JSON.parse(result) });
    }
    return config;
  }

  /**
   * @description
   * Create the singleton dcapi object.
   * @constructor
   * @param {object} config - Can be a boolean to indicate that we want an anonymous dcapi environment when not signed in.
   * Or can be adcapi configuration object. See the Common JSON Configuration section at:
   * @see
   * https://wiki.corp.adobe.com/display/aic/DC+SDK+Technical+Design#DCSDKTechnicalDesign-SDKConfigurationandCodingStyles
   */
  constructor(config) {
    if (config.allowAnonymous) {
      if (auth2.isSignedIn) {
        this.dcapi = new API(config);
      } else {
        this.dcapi = new API(DcapiAPI.getAnonConfig());
      }
    } else {
      this.dcapi = new API(config);
    }
  }

  /**
   * @description
   * Get the singleton dcapi object
   * @method
   * @returns  {DcapiAPI} dcapi - a AdobeDC.API instance
   * @method
   */
  getDcapi() {
    this.dcapi.getUuid = operation => `${operation}-${uuidv4()}`;
    /**
     * Add a listener for dcapi requests
     * fn is a callback function that will
     * receive the input parameters to dcapi.call(), along with
     * the response (a promise)
     */
    this.dcapi.addListener = fn => {
      listeners.push(fn);
    };
    this.dcapi.addErrorListener = fn => {
      errorListeners.push(fn);
    };
    this.dcapi.removeListener = fn => {
      const pos = listeners.indexOf(fn);
      if (pos !== -1) {
        listeners.splice(pos, 1);
      }
    };
    this.dcapi.removeErrorListener = fn => {
      const pos = errorListeners.indexOf(fn);
      if (pos !== -1) {
        errorListeners.splice(pos, 1);
      }
    };
    return this.dcapi;
  }

  /**
   * @description
   * Standard provider ready() method to allow lazy instantiation of API.
   * Resolve or reject with the dcapi.promise() aka dcapi.ready() created an AdobeDC.API instance.
   * @method
   * @returns {Promise} - promise that resolves when router provider has been instantiated
   */
  ready() {
    return this.dcapi.ready().then(() => this);
  }
}

// put the original callAPI as a static method.
// This makes it easier to mock in tests.
DcapiAPI.callAPI = API.callAPI;
// Ensure the general callAPI always specifies an x-request-id.
// eslint-disable-next-line
API.callAPI = function (operation, options) {
  if (!options) options = {};
  if (!options.headers) options.headers = {};
  if (!options.headers['x-request-id'] && !options.headers['X-Request-Id']) {
    options.headers['x-request-id'] = uuidv4();
  }
  // temporary code to avoid calling missing methods for dcapi-facade (assets.put_metadata_field)
  if (operation === 'assets.put_metadata_field') {
    if (!this.resources.assets.put_metadata_field) {
      return Promise.resolve({ status: 200 });
    }
  }

  // check to see if we have a cached discovery response
  const response = DcapiAPI.fetchCache(operation);
  if (response) {
    return Promise.resolve(response);
  }
  const promise = DcapiAPI.callAPI.call(this, operation, options);
  promise.then(result => notifyListeners(operation, options, result), error => notifyErrorListeners(operation, options, error));
  return promise;
};

// This allows for providers.x().then() to be called before providers.x(config).
DcapiAPI.getInstance = getSingletonFunction(DcapiAPI, /* argsRequired*/ true, /* neverReject*/ true);
export default DcapiAPI;
