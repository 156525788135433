/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2017 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
/* global CORE_PACKAGE, window */
/* eslint no-throw-literal:0 */

/**
 * regenerator-runtime is required to use async/await.
 * This needs to be at the top of the application entry point.
 * See https://babeljs.io/docs/en/babel-polyfill#usage-in-node-browserify-webpack
 * */
import 'regenerator-runtime/runtime';

import * as domUtil from './core/DomUtil';
import * as envUtil from './core/EnvUtil';
import * as providerUtil from './core/ProviderUtil';
import bindAndCatch from './core/bindAndCatch';
import Deferred from './core/Deferred';
import DcExtensionStorage from './core/DcExtensionStorage';
import { auth2 } from './services/Auth2API';
import { locale2 } from './services/Locale2API';
import { logging } from './services/LoggingAPI';
import { addProvider, providers } from './services/Providers';
import { router } from './services/RouterAPI';
import { analytics } from './services/Analytics';
// eslint-disable-next-line import/named
import { discovery } from './services/Discovery';
import withHoc from './core/withHoc';

const corePackage = CORE_PACKAGE;

// Check that dc-core has been built properly
window.adobe_dc_sdk = window.adobe_dc_sdk || {};
if (window.adobe_dc_sdk['dc-core-loaded']) {
  // normally this means that dc-core has been statically linked
  throw 'dc-core loaded twice';
}
window.adobe_dc_sdk['dc-core-loaded'] = true;

export {
  addProvider,
  analytics,
  bindAndCatch,
  discovery,
  Deferred,
  DcExtensionStorage,
  domUtil,
  envUtil,
  auth2,
  corePackage,
  locale2,
  logging,
  providers,
  providerUtil,
  router,
  withHoc,
};
