/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2020 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* global window */

import EventEmitter from 'eventemitter3';
import { getSingletonFunction } from '../core/ProviderUtil';
// eslint-disable-next-line import/no-cycle
import { providers } from './Providers';
import DcapiAPI from './DcapiAPI';

const LIMITS_EXPIRY = 'limits-expiry';
const DEFAULT_LIMITS = {
  combine_pdf_conversions: 2,
  combine_pdf_documents: 100,
  combine_pdf_starts: 10,
  conversion_downloads: 1,
  conversion_uploads: 300,
  create_pdf_conversions: 2,
  create_pdf_starts: 10,
  export_pdf_conversions: 2,
  export_pdf_starts: 10,
  ocr_pdf_conversions: 0,
  optimize_pdf_ops: 2,
  optimize_pdf_starts: 10,
  organize_pdf_conversions: 0,
  organize_pdf_documents: 0,
  split_pdf_conversions: 2,
  split_pdf_max_split_points: 20,
  split_pdf_starts: 10,
  password_encrypt_ops: 2,
  password_encrypt_starts: 10,
  upload_starts: 300,
};
const EXHAUSTED_LIMITS = {
  combine_pdf_conversions: {
    remaining: 0,
  },
  combine_pdf_documents: {
    remaining: 0,
  },
  combine_pdf_starts: {
    remaining: 0,
  },
  conversion_downloads: {
    remaining: 0,
  },
  conversion_uploads: {
    remaining: 0,
  },
  create_pdf_conversions: {
    remaining: 0,
  },
  create_pdf_starts: {
    remaining: 0,
  },
  export_pdf_conversions: {
    remaining: 0,
  },
  export_pdf_starts: {
    remaining: 0,
  },
  ocr_pdf_conversions: {
    remaining: 0,
  },
  optimize_pdf_ops: {
    remaining: 0,
  },
  optimize_pdf_starts: {
    remaining: 0,
  },
  organize_pdf_conversions: {
    remaining: 0,
  },
  organize_pdf_documents: {
    remaining: 0,
  },
  split_pdf_conversions: {
    remaining: 0,
  },
  split_pdf_max_split_points: {
    remaining: 0,
  },
  split_pdf_starts: {
    remaining: 0,
  },
  password_encrypt_ops: {
    remaining: 0,
  },
  password_encrypt_starts: {
    remaining: 0,
  },
  upload_starts: {
    remaining: 0,
  },
};
const PDF_ACTIONS_TO_LIMITS = {
  password_encrypt: {
    operation: 'password_encrypt_ops',
    starts: 'password_encrypt_starts',
  },
  optimize: {
    operation: 'optimize_pdf_ops',
    starts: 'optimize_pdf_starts',
  },
  combine: {
    operation: 'combine_pdf_conversions',
    starts: 'combine_pdf_starts',
  },
  organize: {
    operation: 'organize_pdf_conversions',
    starts: 'organize_pdf_starts',
  },
};
let listener;

// debug/test info for QE
const limitsInfo = limits => {
  const serverIdleTime = Date.now() - limits.serverActivityTime;
  const timeLeft = Math.max(limits.serverTrustTime - serverIdleTime, 0);
  const floor = n => Math.floor(n * 100.0) / 100;
  return {
    limits: limits.limits,
    timeToExpiry: `${(floor((limits.getLocalExpiry() - Date.now()) / (1000.0 * 60 * 60)))} hours`,
    timeToTrustServer: `${floor(timeLeft / (1000.0 * 60))} minutes`,
  };
};

class LimitsProvider {
  static getInstance = getSingletonFunction(LimitsProvider);

  serverTrustTime = 5 * 60 * 1000;

  userLimitsDebounce = 5000;

  syncServerLimits = async useCache => {
    if (!useCache) {
      this.userProvider.clear();
    }
    let newLimits;
    try {
      newLimits = await this.userProvider.getLimitsConversions();
    } catch (e) {
      newLimits = EXHAUSTED_LIMITS;
    }
    const filteredLimits = {};
    this.staticLimits = {};
    // we're interested only in those limits that have a 'remaining' property
    Object.keys(newLimits).forEach(limit => {
      if (newLimits[limit].remaining !== undefined) {
        filteredLimits[limit] = newLimits[limit].remaining;
      }
      if (newLimits[limit].limit !== undefined) {
        this.staticLimits[limit] = newLimits[limit].limit;
      }
    });
    const serverIdleTime = Date.now() - this.serverActivityTime;
    // trust the server after 5 minutes
    const trustServerLimits = serverIdleTime > this.serverTrustTime;
    // returns a boolean to indicate whether the limits changed
    return this.mergeLimits(filteredLimits, trustServerLimits);
  }

  expiryTimer = null;

  limits = {};

  staticLimits = {};

  _emitter = new EventEmitter();

  userProvider = null;

  get emitter() {
    return this._emitter;
  }

  /**
   * monitor dcapi activity, and optimistically decrement our local limits accordingly
   * This allows our limits to be immediately accurate instead of wating for server
   * eventual consistency.
   */
  listener = (operation, params, result) => {
    // for corresponding server mappings, see:
    // https://git.corp.adobe.com/dc/pdfnowservice/blob/master/src/main/resources/throttling.yml
    let changed = false;
    const decrement = (limits, key) => {
      if (limits[key] > 0) {
        limits[key] -= 1;
        changed = true;
      }
    };
    const restart = () => { this.currentOperation = undefined; };
    if (operation === 'assets.upload' || operation === 'assets.block_upload_initialize') {
      restart();
      decrement(this.limits, 'upload_starts');
    }
    if (operation === 'assets.createpdf') {
      this.currentOperation = 'create_pdf_conversions';
      decrement(this.limits, 'create_pdf_starts');
    }
    if (operation === 'assets.pdf_actions') {
      let currentOperation = 'optimize_pdf_ops';
      let starts = 'optimize_pdf_starts';
      if (params
          && params.content
          && params.content.pdf_actions) {
        // Here is an example from compress of how params would look like for a pdf_action
        // For anon, we dont allow multiple PDF Actions
        /*
        {
          accept: "new_asset_job_v1.json"
          content:
          assets: [{…}]
          name: "..."
          pdf_actions: [
            {
              optimize: {compress: true}
            }
          ]
        }
        */
        const pdfActionObjectKeys = Object.keys(params.content.pdf_actions[0]);
        if (pdfActionObjectKeys && PDF_ACTIONS_TO_LIMITS[pdfActionObjectKeys[0]]) {
          currentOperation = PDF_ACTIONS_TO_LIMITS[pdfActionObjectKeys[0]].operation;
          starts = PDF_ACTIONS_TO_LIMITS[pdfActionObjectKeys[0]].starts;
        }
      }
      this.currentOperation = currentOperation;
      decrement(this.limits, starts);
    }
    if (operation === 'assets.exportpdf') {
      this.currentOperation = 'export_pdf_conversions';
      decrement(this.limits, 'export_pdf_starts');
    }
    if (operation === 'assets.splitpdf') {
      this.currentOperation = 'split_pdf_conversions';
      decrement(this.limits, 'split_pdf_starts');
    }
    if (operation === 'jobs.status') {
      if (!this.currentOperation) return;
      if (result.content.status === 'done') {
        decrement(this.limits, this.currentOperation);
        restart();
      }
    }
    if (operation === 'assets.download_uri') {
      decrement(this.limits, 'conversion_downloads');
    }
    /**
     * Keep track of the last server operation.
     * After a period of time for eventual consistency to settle down, we will trust it again.
     * Dont count operations on users.
     */
    if (!operation.startsWith('user')) {
      this.serverActivityTime = Date.now();
    }
    if (changed) {
      this.publish();
    }
  }

  ready = async () => {
    this.userProvider = await providers.user();
    // load limits without clearing them first
    // first time in, we can trust that they're fresh
    await this.loadLimits(true);
    this.scheduleExpiry();
    this.listener = this.listener.bind(this);
    DcapiAPI.getInstance().then(api => {
      const dcapi = api.getDcapi();
      dcapi.addListener(this.listener);
    });
    this.serverActivityTime = Date.now();
    if (window.adobe_dc_sdk) {
      window.adobe_dc_sdk.limitsInfo = () => limitsInfo(this);
    }
    return this;
  }

  terminate() {
    DcapiAPI.getInstance().then(api => {
      const dcapi = api.getDcapi();
      dcapi.removeListener(listener);
    });
  }

  cancelExpiry = () => this.expiryTimer && clearTimeout(this.expiryTimer);

  setExpiry = (ms = 24 * 60 * 60 * 1000) => {
    this.setLocalInt(LIMITS_EXPIRY, Date.now() + ms);
  }

  getLocalExpiry = () => {
    let localStorageValue = null;
    try {
      localStorageValue = window.localStorage.getItem(LIMITS_EXPIRY);
    } catch (e) {
      // ignore
    }
    let localVal = null;
    if (localStorageValue !== null) {
      localVal = parseInt(localStorageValue, 10);
    }
    return localVal;
  };

  scheduleExpiry = reset => {
    let expiry = this.getLocalExpiry();
    if (expiry === null || reset) {
      // First time user
      this.setExpiry();
      expiry = this.getLocalExpiry();
    }
    const now = Date.now();
    const howLongToWait = expiry - now;
    this.cancelExpiry();
    if (howLongToWait > 0) {
      this.expiryTimer = setTimeout(
        this.onLimitsExpired,
        howLongToWait,
      );
    }
  }

  onLimitsExpired = async () => {
    await this.reset();
    this.scheduleExpiry(true);
  }

  subscribe = subscriber => {
    this.emitter.addListener('change', subscriber);
  }

  unsubscribe = subscriber => {
    this.emitter.removeListener('change', subscriber);
  }

  limitsExpired = () => {
    const localExpiry = this.getLocalExpiry();
    if (localExpiry === null) {
      return false;
    }
    return (Date.now() > localExpiry);
  };

  loadLimitsTimeout;

  /**
   * this async method cleares the user provider to cause
   * a fresh `user/self` to be retrieved, and then
   * awaits the limits key of the `self` response.
   * This method is debounced so that we don't call it multiple times at startup.
   * Once every five seconds...
   */
  loadLimits = async useCache => {
    const later = () => {
      this.loadLimitsTimeout = null;
    };
    const callNow = !this.loadLimitsTimeout;
    if (callNow) {
      this.loadLimitsTimeout = setTimeout(later, this.userLimitsDebounce);
      const serverChanged = await this.syncServerLimits(useCache);
      if (serverChanged) {
        // today, any change in server limits needs to trigger a re-set
        // on our expiry time.  In the future, we need to do this based on
        // actual times returned by the server
        if (this.limitsExpired()) this.scheduleExpiry(true);
        this.publish();
      }
    }
    return this.limits;
  }

  mergeLimits(newLimits, trustNewLimits) {
    // If the server limits are lower, use them.
    // If they're not lower, then they're probably not current
    // (unless 24 hours has expired)
    // use the trustNewLimits parameter to determine if the input limits are current
    let changed = false;
    Object.keys(newLimits).forEach(limit => {
      if (this.limits[limit] === undefined || newLimits[limit] < this.limits[limit]) {
        this.limits[limit] = newLimits[limit];
        changed = true;
      } else if (this.limits[limit] === undefined || trustNewLimits && (newLimits[limit] !== this.limits[limit])) {
        this.limits[limit] = newLimits[limit];
        changed = true;
      }
    });
    return changed;
  }

  getExhaustedLimits = () => Object.keys(this.limits).filter(limit => this.limits[limit] === 0);

  getUsedLimits = () => Object.keys(this.limits).filter(limit => this.limits[limit] < DEFAULT_LIMITS[limit]);

  getStaticLimits = () => ({ ...this.staticLimits });

  getLimit = limitName => this.limits[limitName] || 0;

  reset = async () => {
    this.limits = {};
    clearTimeout(this.loadLimitsTimeout);
    this.loadLimitsTimeout = null;
    await this.loadLimits();
  }

  setLocalInt = (item, intValue) => {
    try {
      window.localStorage.setItem(item, intValue.toString());
    } catch (e) {
      // ignore
    }
  }

  publish = () => {
    this.emitter.emit('change', {
      nextExhaustedLimits: [...this.getExhaustedLimits()],
      nextLimits: { ...this.limits },
      nextUsedLimits: [...this.getUsedLimits()],
    });
  }
}
export default LimitsProvider;
