/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2019 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/
/* global AbortController, fetch */

function isFunction(val) {
  return typeof val === 'function';
}

function isObject(val) {
  return typeof val === 'object';
}

function isSymbol(val) {
  // Handle a native Symbol and a polyfill Symbol.
  return typeof val === 'symbol' || (val != null && val.constructor != null && val.constructor.name === 'Symbol');
}

/**
 * Deep clone, returning a new/cloned obj.
 * Uses appropriate handling for undefined, null, primitives, arrays, simple {...} and Object.create(null) objects.
 * In general it will use new obj.constructor(obj) for non-simple objects, e.g. Date, RegExp, or MyObject.
 * Warning: functions are referenced, not cloned!
 * Warning: Does NOT check for cyclical references!
 * Warning: Does NOT have special handling for freeze, seal, etc.
 */
function cloneDeep(obj) {
  // Just return immutable-ish privitive or function values.
  if (obj == null || !isObject(obj) || isSymbol(obj) || isFunction(obj)) return obj;
  const Construct = obj.constructor;
  let result;
  if (Construct === Object || Construct === undefined) {
    // Handle simple or Object.create(null) objects.
    result = Construct === undefined ? Object.create(null) : {};
    Object.keys(obj).forEach(key => {
      result[key] = cloneDeep(obj[key]);
    });
    return result;
  }
  if (Array.isArray(obj)) {
    // Efficient handling for Array number indicies rather than Map-Object string keys.
    result = new Construct(obj.length);
    for (let i = 0, n = obj.length; i < n; i++) {
      result[i] = cloneDeep(obj[i]);
    }
    return result;
  }
  return new Construct(obj);
}

async function fetchWithTimeout(resource, options = {}) {
  const { timeout = 3000 } = options;

  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });
  clearTimeout(timeoutId);
  return response;
}

export {
  cloneDeep,
  isFunction,
  isObject,
  isSymbol,
  fetchWithTimeout,
};
