/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/

/* eslint no-undef: 0 */

/**
 * @file
 */
import loadExtras from './loadExtras';
import { getSingletonFunction } from '../core/ProviderUtil';

let sophiaImpl;

/**
   * @classdesc
   * Sophia Provider to manage sophia surfaces
   * @class
   */
class SophiaProvider {
  /**
     * @constructor
     * @param {Object} obj
     * @param {AuthAPI} obj.auth - The instance of AuthAPI for testing.
     * @param {Array} obj.surfaceIds - List of all the surfaces data that needs to be fetched
     * @param {String} obj.sophiaUri - uri to make sophia API calls
     * @param {String} obj.imsClientId - ims client id registered with sophia
     * @param {String} obj.imsToken - The instance of AuthAPI for testing.
     * @param {String} obj.useAnonymousUUID - Use userUUID to fetch sophia for anonymous users.
     * @param {Object} obj.context - Key/value pairs to be passed as context to sophia
     * Default value is false.
     */
  constructor(obj) {
    this.loadPromise = loadExtras().then(() => {
      this.SophiaImplementation = window.adobe_dc_sdk.SophiaImplementation;
      sophiaImpl = new this.SophiaImplementation(obj);
      return sophiaImpl.ready();
    });
  }

  /**
     * @description
     * Standard provider ready() method to allow lazy instantiation of API.
     * @method
     * @returns {Promise} - promise that resolves when Sophia provider has been instantiated
     */
  ready() {
    return this.loadPromise;
  }
}
// This allows for providers.x().then() to be called before providers.x(config).
SophiaProvider.getInstance = getSingletonFunction(SophiaProvider);

export default SophiaProvider;
