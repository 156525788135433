/*************************************************************************
* ADOBE CONFIDENTIAL
* ___________________
*
*  Copyright 2019 Adobe
*  All Rights Reserved.
*
* NOTICE:  All information contained herein is, and remains
* the property of Adobe and its suppliers, if any. The intellectual
* and technical concepts contained herein are proprietary to Adobe
* and its suppliers and are protected by all applicable intellectual
* property laws, including trade secret and copyright laws.
* Dissemination of this information or reproduction of this material
* is strictly forbidden unless prior written permission is obtained
* from Adobe.
**************************************************************************/

import * as React from 'react';
import { providers } from '../services/Providers';

/**
 * This is the single hoc which we need in core allowing us to asynchronously
 * load any other hoc which any provider may provide for external consumers to
 * utilize as a standard facade to one of that provider's features.
 *
 * @param {string} hocName this is the name of the method to be utilized as an hoc
 * @param {string} providerName this is the provider to load it from. This parameter
 *                              is second because when hoc's are discoverable, we can
 *                              much more easily remove the 2nd argument, when we no
 *                              longer need to specify the provider explicitly.
 *                              if this argument is intended to be a permanent pattern
 *                              then I would prefer to have `providerName` as the first
 *                              argument, since that is more logical.
 */
const withHoc = (hocName, providerName) => (...hocArgs) => ComponentClassToWrap => class WithHoc extends React.Component {
          Wrapped = null;

          constructor(props) {
            super(props);
            this.state = {
              loaded: false,
            };
            this.loadHoc();
          }

          loadHoc = async () => {
            // we can load hoc's from any provider! :)
            const hocProvider = (await providers[providerName]());
            // await this in case this is an async `import`
            const hoc = await hocProvider[hocName];
            const higherOrderFunction = hoc(...hocArgs);
            const Wrapped = higherOrderFunction(ComponentClassToWrap);
            this.Wrapped = Wrapped;
            this.setState({
              loaded: true,
            });
          }

          render() {
            const { loaded } = this.state;
            if (!loaded) {
              return null;
            }
            const Wrapped = this.Wrapped;
            return <Wrapped {...this.props} />;
          }
};

export default withHoc;
