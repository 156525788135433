/*************************************************************************
 *  Copyright 2018 Adobe Systems Incorporated. All rights reserved.
 *  This file is licensed to you under the Apache License, Version 2.0 (the "License");
 *  you may not use this file except in compliance with the License. You may obtain a copy
 *  of the License at http://www.apache.org/licenses/LICENSE-2.0
 *
 *  Unless required by applicable law or agreed to in writing, software distributed under
 *  the License is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR REPRESENTATIONS
 *  OF ANY KIND, either express or implied. See the License for the specific language
 *  governing permissions and limitations under the License.
 **************************************************************************/
/**
 * @file
 */

// eslint-disable-next-line import/named
import { discovery } from './Discovery';
import { logging } from './LoggingAPI';

const logger = logging.getLogger('Analytics');

/**
 * @classdesc
 * This is a temporary shell implementation that will allow
 * dropins to begin coding their analytics.  Implementation to follow.
 * @class
 */
class Analytics {
  _maxInitialEvents = 50;

  _initialEvents = [];

  _config;

  _initialized;

  /**
   * Configures the Analytics provider and then sends all initial events.
   * Once this method is called with both arguments, all subsequent calls will be ignored.
   * @method
   * @param {object} config -- the new configuration
   * @param {function} sendEvent -- the new sendEvent function
   * @public
   */
  configure(config, sendEvent) {
    if (!this._initialized) {
      this._initialized = !!(config && sendEvent);
      if (config) {
        this._config = config;
      }
      if (sendEvent) {
        this.sendEvent = sendEvent;
        this._initialEvents.forEach(event => {
          this.sendEvent(event.eventData, event.options);
        });
        this._initialEvents.length = 0;
      }
    }
  }

  /**
   * Sends the analytics event by just adding it to initial list
   * @method
   * @param {object} eventData -- payload of an analytics event
   * @returns {object} - a promise which resolves if adding to initial list is successful
   * @public
   */
  sendEvent(eventData, options) {
    if (this._initialEvents.length < this._maxInitialEvents) {
      this._initialEvents.push({ eventData, options });
      return Promise.resolve();
    }
    logger.error('Reached maximum number of initial events');
    return Promise.resolve();
  }

  /**
   * Get a logger that will add name/version to all analytics requests
   * @method
   * @param {string} id -- id of the dropin (or provider).  To be used as source.name
   * @returns {function} - a logger function that wraps sendEvent
   * @public
   */
  getEventSender(id) {
    if (!id) {
      throw ReferenceError('missing dropin id');
    }
    return (eventData, options) => {
      if (!eventData.source) {
        eventData.source = {};
      }
      eventData.source.name = eventData.source.name || id;
      // code defensively so that unit tests that don't load discovery won't fail
      if (!eventData.source.version && discovery.dropins[id]) {
        eventData.source.version = discovery.dropins[id].version;
      }
      return this.sendEvent(eventData, options);
    };
  }
}
const analytics = new Analytics();

export { analytics };
export default Analytics;
