/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2021 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/**
 * @file
 */
/* global window, localStorage */
/* eslint-disable max-classes-per-file */

import React, { Component } from 'react';
import { addLocaleData as intlAddLocaleData } from 'react-intl';

const defaultLocale = 'en-US';
const localeData = {
  en: require('react-intl/locale-data/en'),
  fr: require('react-intl/locale-data/fr'),
  de: require('react-intl/locale-data/de'),
  ja: require('react-intl/locale-data/ja'),
  es: require('react-intl/locale-data/es'),
  it: require('react-intl/locale-data/it'),
  pt: require('react-intl/locale-data/pt'),
  nl: require('react-intl/locale-data/nl'),
  da: require('react-intl/locale-data/da'),
  sv: require('react-intl/locale-data/sv'),
  nb: require('react-intl/locale-data/nb'),
  fi: require('react-intl/locale-data/fi'),
  zh: require('react-intl/locale-data/zh'),
  ko: require('react-intl/locale-data/ko'),
  cs: require('react-intl/locale-data/cs'),
  pl: require('react-intl/locale-data/pl'),
  ru: require('react-intl/locale-data/ru'),
  tr: require('react-intl/locale-data/tr'),
};

import { setLocale as setSpectrumLocale } from '@react/react-spectrum/utils/intl';

import { logging } from './LoggingAPI';
import UserAPI from './UserAPI';
import locales from './locales.json';

const logger = logging.getLogger('Locale2API');
const state = { locale: undefined };

let stateListeners = [];
/**
 * @description
 * Set the current locale
 * @param {string} newLocale - The new locale value
 */
const setLocale = newLocale => {
  if (state.locale === newLocale) return;
  state.locale = newLocale;
  try {
    localStorage.setItem('locale', newLocale);
  } catch (e) {
    // squelch if local storage is not available
    logger.warn('Cannot access localStorage', e);
  }

  stateListeners.forEach(l => l({ locale: newLocale }));
  // we're never going to update locale more than once
  // So after notifying our listeners, unregister all the listeners
  stateListeners = [];
};

/**
 * @classdesc
 * Provides locale info.
 * @class
 */
class Locale2API {
  constructor() {
    setSpectrumLocale(this.getLocale());
  }

  /** The locale specified in the user's preferences */
  get userLocale() {
    return state;
  }

  useState(listener) {
    stateListeners.push(listener);
  }

  getFinalLocale() {
    return UserAPI.getInstance()
      .then(user => user.getIdentity())
      .then(identity => {
        setLocale(identity.language);
        return identity.language;
      });
  }

  overrideLocale(locale) {
    setLocale(locale);
  }

  /**
   * Formats given locale string to standard style, e.g. 'fr-FR'
   * @param {string} locale - Locale string w/ two-letter language code,
   *      separator, and two-letter region code
   * @returns {string} Locale string in standard format
   */
  formatLocale(locale) {
    const sdk = window.adobe_dc_sdk;

    // sdk.dom is sometimes not available in jest scripts
    return sdk && sdk.dom ? window.adobe_dc_sdk.dom.formatLocale(locale) : locale;
  }

  /**
   * Formats given locale string to standard style, e.g. 'fr-FR', and ensures that the locale is
   * in the set of supported locales.
   * @param {string} locale - Locale string w/ two-letter language code,
   *      separator, and two-letter region code
   * @returns {string} Locale string in standard format
   */
  normalizeLocale(locale) {
    const sdk = window.adobe_dc_sdk;

    // sdk.dom is sometimes not available in jest scripts
    return sdk && sdk.dom ? window.adobe_dc_sdk.dom.normalizeLocale(locale, logger) : locale;
  }

  /**
   * @description
   * Determines the "raw" locale based on the following precendence:
   *  1. Query parameter
   *  2. Browser path: /geo/lang
   *  3. HTML local storage
   *  4. Browser default language
   *
   * @method
   * @returns {string} - The normalized raw locale (e.g. de-DE).
   * @public
   */
  getRawLocale() {
    if (this.cachedRawLocale) {
      return this.cachedRawLocale;
    }

    // sdk.dom is sometimes not available in jest scripts
    const sdk = window.adobe_dc_sdk;

    // First check if locale is available from path/query param override
    // If yes, just return here
    this.cachedRawLocale = sdk && sdk.dom && sdk.dom.getLocaleOverride();
    if (this.cachedRawLocale) {
      return this.cachedRawLocale;
    }

    // If not, use the complete logic to get the locale
    this.cachedRawLocale = sdk && sdk.dom ? sdk.dom.getLocale(true) : 'en-US';
    this.getFinalLocale();

    return this.cachedRawLocale;
  }

  /**
   * @description
   * Determines the locale based on the following precendence:
   *  1. Query parameter
   *  2. HTML local storage
   *  3. Browser default language
   *  4. "en-US"
   *
   * @method
   * @returns {string} - The normalized locale (e.g. de-DE).
   * @public
   */
  getLocale() {
    if (this.cachedLocale) {
      return this.cachedLocale;
    }

    const rawLocale = this.getRawLocale();
    this.cachedLocale = this.normalizeLocale(rawLocale);
    return this.cachedLocale;
  }

  /**
   * Tells whether current locale is in CCJK family
   * @returns {Boolean} True if a CCJK locale
   */
  get isCCJKLocale() {
    const locale = this.getLocale();
    return locale === 'ja-JP' || locale === 'zh-CN' || locale === 'zh-TW' || locale === 'ko-KR';
  }

  /**
   * Gets the locales supported by DC Web.
   * @returns {Object} - The locales keyed by code (e.g. de-DE) and valued by name (e.g. Deutsch).
   */
  get supportedLocales() {
    return window?.adobe_dc_sdk?.cache?.supportedLocales || locales;
  }

  /**
   * Registers the data for the given locale with the react-intl library.
   * @param {string} locale The locale to be registered (e.g. de). Must be given.
   * @return {Promise} A Promise to register the given locale with react-intl.
   */
  addLocaleData(locale) {
    if (!locale) {
      throw new Error('locale must be given!');
    }

    let selectedLocale = localeData[locale];
    if (!selectedLocale) {
      logger.warn(`Locale ${locale} not currently supported. Defaulting to en-US...`);
      selectedLocale = localeData.en;
    }

    return Promise.resolve(intlAddLocaleData(selectedLocale));
  }

  /**
   * Loads the translations file for the given locale, defaulting to en-US if the translations file for the given
   * locale doesn't exist.
   * @param {Function} load A function which loads a translations file. Takes one parameter, a locale (e.g. de-DE).
   *                        Returns a Promise which resolves with map of translations. Must be given.
   * @param {string} locale The locale whose translations will be loaded (e.g. de-DE). Must be given.
   * @return {Promise} A Promise to load the translations for the given locale.
   */
  loadTranslations(load, locale) {
    if (!load) {
      throw new Error('load function must be given!');
    }
    if (!locale) {
      throw new Error('locale must be given!');
    }

    return load(locale).catch(
      err => {
        if (err.message.search('Cannot find module') !== -1) {
          logger.error(`Cannot find ${locale} translations file. Defaulting to ${defaultLocale}...`);
          return load(defaultLocale);
        }
        throw err;
      },
    );
  }

  /**
   * @description
   * Higher-order component generator which returns the target component with
   * 'locale' and 'messages' properties added. Assumes they're used by target's
   * IntlProvider. Actual loading of messages is done via passed function so
   * that it occurs in the target component's folder.
   * @method
   * @param {Function} WrappedComponent - Component with localized text
   * @param {Function} loadTranslations - Function which loads translations in
   *      context of wrapped component. Takes one parameter, locale, e.g. "fr-FR".
   *      Returns promise which resolves with map of translations.
   * @param {Object} options - May contain:
   *    {String} locale - If supplied, e.g. 'fr-fr', overrides other sources
   *    {boolean} usePromise - If true, the messages passed into WrappedComponent will be wrapped in a Promise.
   * @returns {Object} -  Target react based component with 'locale' and 'messages' properties.
   * @public
   */
  withTranslations(WrappedComponent, loadTranslations, options) {
    const self = this;
    options = options || {};

    class Localized extends Component {
      constructor(props, context) {
        super(props, context);
        this.state = {
          locale: this.locale,
          messages: undefined,
        };
        this.loadMessages();
      }

      /**
       * Current locale
       */
      get locale() {
        let passedLocale;
        if (options.locale) {
          passedLocale = options.locale;
        }
        return passedLocale ? self.normalizeLocale(passedLocale) : self.getLocale();
      }

      // Loads translations for current locale. If not 'en-US', loads that too
      // so that we default to that text if translation is not yet available.
      loadMessages() {
        this.messagesPromise = loadTranslations(this.locale).then(msgs => {
          this.setState({ messages: msgs });
          return msgs;
        }).catch(() => {
          logger.warn(`Missing translation for ${this.locale}`);
        });
      }

      render() {
        const { locale, messages } = this.state;
        if (options.usePromise === true) {
          return <WrappedComponent locale={locale} {...this.props} messages={this.messagesPromise} />;
        }
        if (!messages) {
          return null;
        }
        return <WrappedComponent locale={locale} {...this.props} messages={messages} />;
      }
    }

    return Localized;
  }

  /**
   * @description
   * Function searches for supported language example: language is la-ES and we are searching
   * if that language exists in out locale codes
   * @method
   * @param {Array} localeCodes - string array of supported locales
   * @param {string} lang - part of language string example: es
   * @returns {(string|undefined)} if language is supported we are returning language code if not
   * undefined is returned
   * @private
   */
  searchForSupportedLanguage(localeCodes, lang) {
    return localeCodes.find(languageCode => languageCode.toLowerCase().startsWith(lang.toLowerCase()));
  }
}

const locale2 = new Locale2API();
// eslint-disable-next-line
export { locale2 };
