/*************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 *  Copyright 2017 Adobe
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 **************************************************************************/
/* global document */

/**
 * @description
 * Get content attribute value from the given named meta tag in the document header.
 * @memberof DomUtil
 * @method
 * @param {string} name - The name of the tag whose value is to be fetched.
 * @returns {Object} - The matching meta tag element
 * @public
 */
function getMetaContent(name) {
  const elem = document.head.querySelector(`meta[name='${name}']`);
  return elem ? elem.getAttribute('content') : null;
}

/**
 * @description
 * Load a script at the given uri, calling the given resolve() or reject().
 * The resolve() or reject() functions will be given a reference to the script tag.
 * Avoiding the Promise will be slightly more efficient.
 * This guards against duplicate loading of scripts unless loadScript.allowDuplicates is true.
 * @memberof DomUtil
 * @method
 * @param {string} uri - The uri for the script
 * @param {Object} resolve - The callaback method when promise is resolved.
 * @param {Object} reject - The callback method when promise is rejected.
 * @returns {Object} - The script that is loaded. Or a Promise if there are no resolve function provided.
 * The script tag will have a loaded boolean, true on resolve, false on reject or timeout.
 * @example
 * sdk.dom.loadScript("index.js", console.info, console.error);
 * Use loadScript({uri: "index.js", async: true}) for async execution of injected scripts (default false).
 * @public
 */
function loadScript(uri, resolve, reject) {
  if (!resolve) {
    // Return a Promise if not given a resolve function.
    return new Promise(((res, rej) => {
      loadScript(uri, res, rej);
    }));
  }
  // Have a flag to allow duplicates for testing.
  const head = document.head;
  let isAsync = false;
  if (typeof uri === 'object') {
    isAsync = !!uri.async;
    uri = uri.uri;
  }
  let script = loadScript.allowDuplicates ? null : head.querySelector(`script[src="${uri}"]`);
  function scriptLoadedOrNot() {
    if (script.loaded) resolve(script);
    else {
      const err = new ReferenceError(`Script failed: ${script.src}`);
      err.script = script;
      if (reject) reject(err);
      else throw err;
    }
  }
  // If already loaded, use a timeout to ensure an async callback.
  if (script && typeof script.loaded === 'boolean') {
    setTimeout(scriptLoadedOrNot, 0);
    return script;
  }
  // If not loaded, do so while monitoring for an error and timeout.
  if (!script) {
    // Check if we should preload scripts to increase their priority in the browser.
    // This should help avoid stalling on TTFB, time to first byte.
    if (loadScript.preload) {
      head.appendChild(
        Object.assign(document.createElement('link'), { rel: 'preload', as: 'script', href: uri }),
      );
    }
    script = document.createElement('script');
    script.src = uri;
    script.async = isAsync;
  }
  ['load', 'error', 'timeout'].forEach(key => {
    function onEvent() {
      script.removeEventListener(key, onEvent);
      script.eventType = key;
      script.loaded = key === 'load';
      scriptLoadedOrNot();
    }
    script.addEventListener(key, onEvent, false);
  });
  if (script.loaded === undefined) {
    head.appendChild(script);
  }
  return script;
}
loadScript.allowDuplicates = false;

export {
  getMetaContent,
  loadScript,
};
