/*************************************************************************
 * ADOBE SYSTEMS INCORPORATED
 *  Copyright 2021 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying it.
 * If you have received this file from a source other than Adobe, then your
 * use, modification, or distribution of it requires the prior written
 * permission of Adobe.
 **************************************************************************/

/* eslint no-undef: 0 */

/**
 * @file
 */

import { getSingletonFunction } from '../core/ProviderUtil';
import loadExtras from './loadExtras';

/**
 * @classdesc
 * A service provider for asset-related DCAPI operations.
 * @class
 * @see {@link https://createpdf.acrobat.com/static/documentation/dcapi/API/dcapi-static.html|DCAPI}
 */
class Asset2API {
  /**
   * @description
   * Standard provider ready() method to allow lazy instantiation of API.
   * @method
   * @returns {Promise} - promise that resolves when asset provider has been instantiated
   * @public
   */
  ready() {
    return loadExtras().then(() => {
      this.Asset2Implementation = window.adobe_dc_sdk.Asset2Implementation;
      const asset2Impl = new this.Asset2Implementation();
      return asset2Impl.ready();
    });
  }
}

// This allows for providers.x().then() to be called before providers.x(config).
Asset2API.getInstance = getSingletonFunction(Asset2API);
export default Asset2API;
