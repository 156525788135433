/*************************************************************************
 * ADOBE SYSTEMS INCORPORATED
 *  Copyright 2018 Adobe Systems Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  Adobe permits you to use, modify, and distribute this file in
 * accordance with the terms of the Adobe license agreement accompanying it.
 * If you have received this file from a source other than Adobe, then your
 * use, modification, or distribution of it requires the prior written
 * permission of Adobe.
 **************************************************************************/
/**
 * @file
 */

import { http } from 'adobe-dcapi-web';
import { getEnvVar } from '../core/EnvUtil';

import { getSingletonFunction } from '../core/ProviderUtil';
import DcapiAPI from './DcapiAPI';
import { auth2 } from './Auth2API';

export const CONTENT_TYPE_USER = 'user_v1.json';
export const CONTENT_TYPE_LIMITS_ESIGN = 'user_limits_esign_v1.json';
export const CONTENT_TYPE_USER_PREFS = 'user_prefs_v1.json';

export const OPERATION_USER_GET = 'users.get_user';
export const OPERATION_LIMITS_ESIGN = 'users.get_limits_esign';
export const OPERATION_PREFS_GET = 'users.get_prefs';
export const OPERATION_PREFS_PUT = 'users.put_prefs';

let userPromise;
let user = {};
let dcapi;
let dcapiPromise;
let esignPromise;
let sendPromise;
let baseUrisPromise;
let accessTokenPromise;

const getDcapi = () => {
  if (dcapiPromise) {
    return dcapiPromise;
  }
  dcapiPromise = DcapiAPI.getInstance()
    .then(dcapiInstance => dcapiInstance.getDcapi());
  return dcapiPromise;
};

/**
 * @method
 * @returns {object} - success - user aggregated info
 *                     failure - Error
 * @private
 */
const getUser = async () => {
  if (!userPromise) {
    const options = {
      accept: CONTENT_TYPE_USER,
    };

    options.uri_parameters = {
      fields: [
        'identity',
        'limits/acrobat',
        'limits/conversions',
        'limits/pdf_services',
        'limits/esign',
        'limits/verbs',
        'prefs/dcweb',
        'prefs/recent_assets',
        'prefs/recent_assets_timestamp',
        'subscriptions',
      ].join(','),
    };

    userPromise = getDcapi().then(api => {
      if (!dcapi) {
        dcapi = api;
      }
    }).then(() => dcapi.call(OPERATION_USER_GET, options))
      .then(({ content }) => {
        user = content;
        // remove prefs content that no longer fits our schema
        const dcwebPrefs = user['prefs/dcweb'];
        if (dcwebPrefs && dcwebPrefs.dcweb) {
          const fte = dcwebPrefs.dcweb.fte;
          if (fte) {
            delete fte.a11y;
            delete fte.launch_count;
          }
          const tour = dcwebPrefs.dcweb.coach_mark_tour;
          if (tour) {
            Object.keys(tour).forEach(t => {
              delete tour[t].lastDateSeen;
              delete tour[t].timesSeen;
            });
          }
        }
        return user;
      });
  }
  return userPromise;
};

/**
 * @method
 * @returns {object} - success - user aggregated info
 *                     failure - Error
 * @private
 */
const getLimitsEsign = async () => {
  if (!esignPromise) {
    esignPromise = getDcapi().then(api => {
      if (!dcapi) {
        dcapi = api;
      }
    }).then(() => dcapi.call(OPERATION_LIMITS_ESIGN, {
      accept: CONTENT_TYPE_LIMITS_ESIGN,
    }))
      .then(({ content }) => content);
  }
  return esignPromise;
};

/**
 * @method
 * @returns {object} - success - the bearer access token
 *                     failure - Error
 * @private
 */
const getAccessToken = () => {
  if (!accessTokenPromise) {
    accessTokenPromise = auth2.getSessionToken()
      .then(({ content }) => {
        if (!content.access_token) {
          return;
        }
        return `Bearer ${content.access_token}`;
      });
  }
  return accessTokenPromise;
};

/**
 * @method
 * @returns {object} - success - the send and track base uris
 *                     failure - Error
 * @private
 */
const getSendAndTrackUris = () => {
  if (!baseUrisPromise) {
    const fileTierBaseUri = getEnvVar('files_uri');
    if (!fileTierBaseUri) {
      return Promise.reject(new ReferenceError('missing files_uri'));
    }
    const headers = {
      Accept: 'application/vnd.adobe.dex+json;version=1',
      'x-api-client-id': 'api_browser',
      'x-api-app-info': 'dc-web-app',
    };

    baseUrisPromise = getAccessToken()
      .then(token => {
        if (token) {
          headers.Authorization = token;
        }
      })
      .then(() => http.get(`${fileTierBaseUri}/api/base_uris`, { headers }))
      .then(({ content }) => JSON.parse(content));
  }
  return baseUrisPromise;
};

/**
 * @method
 * @returns {object} - success - limits info for track and send
 *                     failure - Error
 * @private
 */
const getLimitsSend = () => {
  if (!sendPromise) {
    sendPromise = Promise.all([getSendAndTrackUris(), getAccessToken()])
      .then(([baseUris, accessToken]) => {
        const headers = {
          Authorization: accessToken,
          Accept: 'application/vnd.adobe.dex+json;version=1',
          'x-api-client-id': 'api_browser',
          'x-api-app-info': 'dc-web-app',
        };
        return http.get(`${baseUris.send_api}users/me/limits`, { headers });
      }).then(({ content }) => JSON.parse(content));
  }
  return sendPromise;
};

/**
 * @classdesc
 * Service provider with access to DCAPI
 * Wraps key user info DCAPI calls
 * @class
 */
class UserAPI {
  /** Instance of dcapi client to use */
  dcapi = undefined;

  /**
   * @description
   * Standard provider ready() method to allow lazy instantiation of API.
   * @method
   * @returns {Promise} - promise that resolves when user provider has been instantiated
   */
  ready() {
    return Promise.resolve(this);
  }

  /**
   * @description
   * Discard all cached user information in this provider
   *
   * @returns {object} returns a reference to this provider
   * @method
   */
  clear() {
    userPromise = undefined;
    esignPromise = undefined;
    sendPromise = undefined;
    baseUrisPromise = undefined;
    accessTokenPromise = undefined;
    return this;
  }

  /**
   * @method
   * @returns {object} - success - user aggregated info
   *                     failure - Error
   * @public
   */
  getUser() {
    return getUser().then(userObj => {
      userObj = { ...userObj };
      delete userObj['prefs/dcweb'];
      return userObj;
    });
  }

  /**
   * @method
   * @returns {object} - success - esign limits
   *                     failure - Error
   * @public
   */
  getLimitsEsign() {
    return getLimitsEsign().then(limits => limits.esign_access);
  }

  /**
   * @method
   * @returns {object} - success - send and track limits
   *                     failure - Error
   * @public
   */
  getLimitsSend() {
    return getLimitsSend();
  }

  /**
   * @method
   * @returns {object} - success
   *                        true - if original sharing is enable
   *                        false - otherwise
   *                     failure - if error occurs while fetching limits
   * @public
   */
  isOriginalSharingEnabled() {
    return getLimitsSend()
      .then(limits => limits.original_sharing_enabled)
      .catch(() => false);
  }

  /**
   * @method
   *
   * Get base uris for various services
   *
   * @returns {Promise<object>} returns a promise that resolves with
   * base uris of various services
   *
   * @public
   */
  getFilesTierBaseUris() {
    return getSendAndTrackUris();
  }

  /**
   * @method
   * @returns {object} -  success - user identity
   *         failure - Error
   * @public
   */
  getIdentity() {
    return getUser().then(({ identity }) => identity);
  }

  /**
   * @method
   * @returns {object} -  success - user identity
   *         failure - Error
   * @public
   */
  getSubscriptions() {
    return getUser().then(({ subscriptions }) => subscriptions);
  }

  /**
   * @method
   * @returns {boolean} -  if user is able to edit pdf
   * @public
   */
  canEdit() {
    return getUser().then(res => res['limits/conversions'].edit_pdf_ops.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canExport() {
    return getUser().then(res => res['limits/conversions'].export_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canCreate() {
    return getUser().then(res => res['limits/conversions'].create_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canCombine() {
    return getUser().then(res => res['limits/conversions'].combine_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canOrganize() {
    return getUser().then(res => res['limits/conversions'].organize_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canSplit() {
    return getUser().then(res => res['limits/conversions'].split_pdf_conversions.remaining !== 0);
  }

  /**
   * @method
   * @returns {boolean} -  User does not have 0 remaining conversions
   * @public
   */
  canCompress() {
    return getUser().then(res => res['limits/conversions'].optimize_pdf_ops.remaining !== 0);
  }

  /**
   * @description
   * Gets the user's conversions limits (file size limit, conversions remaining, etc)
   * Schema: https://dc-api-dev.adobe.io/schemas/user_limits_conversions_v1.json
   * @method
   * @returns {object} -  User's conversion limits
   * @public
   */
  getLimitsConversions() {
    return getUser().then(res => res['limits/conversions']);
  }

  /**
   * @method
   * @returns {object} -  User's acrobat limits, e.g. {acrobat_std, acrobat_pro}
   * @public
   */
  getLimitsAcrobat() {
    return getUser().then(res => res['limits/acrobat']);
  }

  /**
   * @description
   * Gets the limits for each verb
   * Schema: https://dc-api-dev.adobe.io/schemas/user_limits_conversions_v1.json
   * @method
   * @returns {object} -  User's conversion limits
   * @public
   */
  getLimitsVerbs() {
    return getUser().then(res => res['limits/verbs']);
  }

  /**
   * @method
   * @returns {boolean} -  if user is able to crop pdf
   * @public
   */
  canCrop() {
    return getUser().then(res => res['limits/verbs']['crop-pages'].limits.remaining !== 0);
  }

  /**
   * @description
   * Gets the values associated with the specified user preference category. If the category
   * is a slash-delimited path (eg. dcweb/fte), this will return only the specified subset. If
   * the subset cannot be found for a valid category, this returns <code>undefined</code>.
   * @method
   * @param {string} category - name of preference category to fetch. This value can also be
   *                            a path (delimited by "/") to a preference subtree or specific
   *                            value.
   *
   * @returns {object} - success - The specified preference(s)
   *         failure - Error
   * @public
   */
  getPreferences(category) {
    const [mainCategory, ...path] = category.split('/');

    return getUser().then(userObj => {
      // use the cached user, in case setPreferences has
      // been called.
      const userPrefs = userObj[`prefs/${mainCategory}`]
        ? userObj[`prefs/${mainCategory}`][mainCategory] : undefined;
      if (!path || path.length === 0) {
        return userPrefs;
      }
      // This will iterate over the path segments to traverse the preference data/tree.
      return path.reduce((prefs, name) => {
        if (prefs && name in prefs) {
          return prefs[name];
        }
        return undefined;
      }, userPrefs);
    });
  }

  /**
   * @description
   * Updates the values assocated with the specified user preference category.
   * @method
   * @param {string} category - name of preference category to set.
   *
   * @param {(object|string)} preferences - Object/String with all or a preference for given category. This
   *                               will completely replace the current category's
   *                               preferences.
   *
   * @returns {Promise} - If successful this resolves without a value. If there is
   *                     a failure, the promise will be rejected with an error object.
   * @public
   */
  setPreferences(category, preferences) {
    const [mainCategory, ...remainingCategories] = category.split('/');

    return this.getPreferences(mainCategory)
      .then(fullPreferences => {
        if (remainingCategories && remainingCategories.length >= 1) {
          let lastLevel = null;
          let lastKey = null;
          remainingCategories.reduce((userPreferences, currentPref) => {
            lastLevel = userPreferences;
            lastKey = currentPref;
            return userPreferences[currentPref]
              ? userPreferences[currentPref] : userPreferences[currentPref] = {};
          }, fullPreferences);
          if (typeof (preferences) === 'string') {
            lastLevel[lastKey] = preferences;
          } else {
            lastLevel[lastKey] = { ...lastLevel[lastKey], ...preferences };
          }
        } else {
          // there is no path in the category, modify the main category
          // eslint-disable-next-line
          if (typeof (preferences) === 'string') {
            fullPreferences = preferences;
          } else {
            fullPreferences = { ...fullPreferences, ...preferences };
          }
        }

        return getUser().then(async userObj => {
          if (!userObj[`prefs/${mainCategory}`]) {
            userObj[`prefs/${mainCategory}`] = {};
          }
          user[`prefs/${mainCategory}`][mainCategory] = fullPreferences;
          if (!dcapi) {
            dcapi = await getDcapi();
          }
          return dcapi.call(OPERATION_PREFS_PUT, {
            content_type: CONTENT_TYPE_USER_PREFS,
            uri_parameters: {
              category: mainCategory,
            },
            content: {
              [mainCategory]: fullPreferences,
            },
          });
        });
      });
  }

  /**
   * @method
   * @returns {boolean} - true if user has only Free level subscriptions
   *         failure - Error
   * @public
   */
  isFreeUser() {
    return this.getSubscriptions().then(subscriptions => subscriptions.subscriptions.every(sub => sub.level === 'Free'));
  }

  /**
   * @method
   * Determines the user's primary storage type
   * @returns Promise<{('ACP' | 'SC')}> - The user storage type which can be either ACP or SC
   * @public
   */
  async getStorageType() {
    if (!dcapi) {
      dcapi = await getDcapi();
    }
    return dcapi.templates.search_uri_primary === '{+search_uri_v2}' ? 'ACP' : 'SC';
  }

  /**
   * Reset DcAPi. Used only for test
   * @private
   */
  __resetDcApi() {
    dcapi = undefined;
    dcapiPromise = undefined;
  }
}

// This allows for providers.x().then() to be called before providers.x(config).
UserAPI.getInstance = getSingletonFunction(UserAPI);
export default UserAPI;
